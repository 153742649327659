define('frontend/mirage/factories/entity-tree-node', ['exports', 'ember-cli-mirage'], function (exports, _emberCliMirage) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.createFromParams = undefined;


  var createFromParams = function createFromParams(server, params) {
    var name = null;
    var path = [params.institute, params.organization, params.provider, params.patient].reduce(function (p, part) {
      if (part) {
        name = part.get('name');
        return (p ? p + '/' : '') + part.get('entityType') + '/' + part.get('id');
      } else {
        return p;
      }
    }, null);

    var segments = [params.institute, params.organization, params.provider, params.patient].reduce(function (segs, part) {
      if (part) {
        var lastPart = segs[segs.length - 1];

        var lastPath = lastPart ? lastPart.path : null;
        var newPath = (lastPath ? lastPath + '/' : '') + part.get('entityType') + '/' + part.get('id');

        segs.addObject({
          name: part.get('name'),
          type: part.get('entityType'),
          path: newPath,
          id: part.get('id')
        });

        return segs;
      } else {
        return segs;
      }
    }, []);

    if (params.institute) {
      params.instituteId = params.institute.get('id');
    }

    if (params.organization) {
      params.organizationId = params.organization.get('id');
    }

    if (params.provider) {
      params.providerId = params.provider.get('id');
    }

    if (params.patient) {
      params.patientId = params.patient.get('id');
    }

    var transformedParams = Ember.merge(params, { segments: segments, path: path, id: path, name: name });

    var node = server.create('entityTreeNode', transformedParams);

    server.create('entityTreeCollection', {
      collection: 'patient-sessions',
      path: node.path + '/patient-sessions',
      id: node.id + '/patient-sessions',
      entityTreeNode: node
    });

    return node;
  };

  exports.createFromParams = createFromParams;
  exports.default = _emberCliMirage.Factory.extend({
    path: function path() {
      return this.id;
    }
  });
});