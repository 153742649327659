define('frontend/mirage/factories/payor', ['exports', 'ember-cli-mirage', 'faker'], function (exports, _emberCliMirage, _faker) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberCliMirage.Factory.extend({
    id: _faker.default.random.uuid,
    name: function name(i) {
      return 'Payor ' + i;
    },
    primaryId: function primaryId(i) {
      return 'payor' + i;
    }
  });
});