define('frontend/mirage/factories/report', ['exports', 'ember-cli-mirage', 'faker'], function (exports, _emberCliMirage, _faker) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberCliMirage.Factory.extend({
    id: _faker.default.random.uuid,
    status: "complete",

    withScales: (0, _emberCliMirage.trait)({
      afterCreate: function afterCreate(report, server) {
        var patient = report.patientSession.patient;
        var scaleIds = [];
        if (!report.reportScales.length) {
          if (report.scaleIds) {
            scaleIds = report.scaleIds;
            report.scaleIds.forEach(function (scaleId) {
              server.create('reportScale', { report: report, scaleId: scaleId, manual: true });
            });
          } else {
            var reportScale = server.create('reportScale', { report: report, manual: true });
            scaleIds = [reportScale.attrs.scaleId];
          }

          server.create('invitation', { report: report, participant: patient, user: patient.user });
        }

        report.attrs.administeredScaleIds = scaleIds;
      }
    })
  });
});