define('frontend/mirage/factories/goal-answer', ['exports', 'ember-cli-mirage'], function (exports, _emberCliMirage) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberCliMirage.Factory.extend({
    goal: (0, _emberCliMirage.association)(),
    user: (0, _emberCliMirage.association)()
  });
});