define('frontend/mirage/factories/active-configuration-value', ['exports', 'ember-cli-mirage'], function (exports, _emberCliMirage) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberCliMirage.Factory.extend({
    configurationProperty: (0, _emberCliMirage.association)(),
    configurationValue: (0, _emberCliMirage.association)()
  });
});