define('frontend/mirage/factories/charge', ['exports', 'ember-cli-mirage', 'faker'], function (exports, _emberCliMirage, _faker) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberCliMirage.Factory.extend({
    id: _faker.default.random.uuid,
    patientSession: (0, _emberCliMirage.association)(),
    payorContractCode: (0, _emberCliMirage.association)(),
    payorContract: (0, _emberCliMirage.association)(),
    provider: (0, _emberCliMirage.association)(),
    patient: (0, _emberCliMirage.association)(),
    organization: (0, _emberCliMirage.association)(),
    chargeItemCode: (0, _emberCliMirage.association)(),

    billableUnits: 1,
    billableAmountCents: 300,

    billedUnits: 1,
    billedAmountCents: 300,

    receivedUnits: 1,
    receivedAmountCents: 200,

    expectedUnits: 1,
    expectedAmountCents: 300,

    plannedUnits: 2,
    plannedAmountCents: 600,

    maxUnits: 4,
    maxAmountCents: 1200,

    submittedAt: _faker.default.date.past(),
    receivedDate: _faker.default.date.recent(),

    status: function status() {
      return _faker.default.random.arrayElement(['billed', 'billable', 'finalized', 'not_billable']);
    },
    algorithmStatus: function algorithmStatus() {
      return _faker.default.random.arrayElement(['fully_reimbursed', 'not_reimbursed_max_year_visits', 'not_reimbursed_max_year_units', 'partially_reimbursed_max_year_units', 'no_eligible_items']);
    }
  });
});