define('frontend/mirage/models/charge-item', ['exports', 'ember-cli-mirage'], function (exports, _emberCliMirage) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberCliMirage.Model.extend({
    charge: (0, _emberCliMirage.belongsTo)(),
    assessmentReportScaleParticipant: (0, _emberCliMirage.belongsTo)('report-scale-participant', { inverse: null })
  });
});