define('frontend/mirage/factories/payor-contract-code', ['exports', 'ember-cli-mirage'], function (exports, _emberCliMirage) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberCliMirage.Factory.extend({
    payorContract: (0, _emberCliMirage.association)(),
    chargeItemCode: (0, _emberCliMirage.association)(),

    maxUnitsPerYear: 20,
    maxUnitsPerVisit: 4,
    maxVisitsPerYear: 20,
    contractedUnitPriceCents: 100,
    listUnitPriceCents: 400
  });
});