define('frontend/mirage/factories/institute', ['exports', 'ember-cli-mirage', 'faker'], function (exports, _emberCliMirage, _faker) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberCliMirage.Factory.extend({
    id: _faker.default.random.uuid,
    name: function name(i) {
      return 'Test Institute ' + i;
    },
    shortcode: function shortcode(i) {
      return 'i' + i;
    },
    patientAuthenticationMethod: "full"
  });
});