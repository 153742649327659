define('frontend/mirage/models/report-scale', ['exports', 'ember-cli-mirage'], function (exports, _emberCliMirage) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberCliMirage.Model.extend({
    scale: (0, _emberCliMirage.belongsTo)({ inverse: null }),
    report: (0, _emberCliMirage.belongsTo)(),
    reportScaleParticipants: (0, _emberCliMirage.hasMany)()
  });
});