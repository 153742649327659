define('frontend/mirage/factories/experiment-variant', ['exports', 'ember-cli-mirage'], function (exports, _emberCliMirage) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberCliMirage.Factory.extend({
    name: function name(i) {
      return 'variant' + i;
    },
    experiment: (0, _emberCliMirage.association)()
  });
});