define("frontend/mirage/models/measurement-plan", ["exports", "ember-cli-mirage"], function (exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberCliMirage.Model.extend({
    entityMeasurementPlans: (0, _emberCliMirage.hasMany)(),
    measurementPlanScales: (0, _emberCliMirage.hasMany)(),
    author: (0, _emberCliMirage.belongsTo)("user")
  });
});