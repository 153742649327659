define('frontend/mirage/factories/experiment-decision', ['exports', 'ember-cli-mirage'], function (exports, _emberCliMirage) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberCliMirage.Factory.extend({
    experiment: (0, _emberCliMirage.association)(),
    experimentVariant: (0, _emberCliMirage.association)(),
    target: (0, _emberCliMirage.association)('patient')
  });
});