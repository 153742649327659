define('frontend/mirage/factories/entity-tree-collection', ['exports', 'ember-cli-mirage'], function (exports, _emberCliMirage) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberCliMirage.Factory.extend({
    collection: function collection() {
      var segments = this.path.split('/');
      return segments[segments.length - 1];
    }
  });
});