define('frontend/mirage/factories/question', ['exports', 'ember-cli-mirage', 'faker'], function (exports, _emberCliMirage, _faker) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberCliMirage.Factory.extend({
    id: _faker.default.random.uuid,

    text: function text(i) {
      return 'Question Text ' + i;
    },

    questionType: "radio"
  });
});