define('frontend/mirage/factories/measurement-plan', ['exports', 'ember-cli-mirage', 'faker'], function (exports, _emberCliMirage, _faker) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberCliMirage.Factory.extend({
    id: _faker.default.random.uuid,
    name: function name(i) {
      return 'Plan ' + i;
    },
    description: function description(i) {
      return 'Description of test assessment ' + i;
    },
    global: false,
    shared: true,
    autogenerated: false,
    defaultPlanType: 'core',
    afterCreate: function afterCreate(mp, server) {
      if (!mp.measurementPlanScales.length === 0) {
        mp.measurementPlanScales = server.createList('measurement-plan-scale', 1, { measurementPlan: mp });
      }
    }
  });
});