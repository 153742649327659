define('frontend/mirage/factories/flag', ['exports', 'ember-cli-mirage', 'faker'], function (exports, _emberCliMirage, _faker) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberCliMirage.Factory.extend({
    id: _faker.default.random.uuid,
    name: function name(i) {
      return 'flag' + i;
    },
    description: function description(i) {
      return 'flag description ' + i;
    },
    icon: 'fa-medkit'
  });
});