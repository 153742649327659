define('frontend/mirage/factories/coverage', ['exports', 'ember-cli-mirage', 'faker'], function (exports, _emberCliMirage, _faker) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberCliMirage.Factory.extend({
    id: _faker.default.random.uuid,
    patient: (0, _emberCliMirage.association)(),
    institutePayor: (0, _emberCliMirage.association)(),

    relationship: _faker.default.random.arrayElement(["self", "child", "parent"]),
    subscriberNumber: function subscriberNumber(i) {
      return i;
    }
  });
});