define('frontend/mirage/models/goal-answer', ['exports', 'ember-cli-mirage'], function (exports, _emberCliMirage) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberCliMirage.Model.extend({
    user: (0, _emberCliMirage.belongsTo)(),
    report: (0, _emberCliMirage.belongsTo)(),
    goal: (0, _emberCliMirage.belongsTo)()
  });
});