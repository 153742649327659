define('frontend/mirage/factories/configuration-property', ['exports', 'ember-cli-mirage', 'faker'], function (exports, _emberCliMirage, _faker) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberCliMirage.Factory.extend({
    id: _faker.default.random.uuid,
    code: function code(i) {
      return 'code' + i;
    },
    name: function name(i) {
      return 'Property ' + i;
    },
    category: 'Assessment',
    description: function description(i) {
      return 'A description of property ' + i;
    },
    valueType: 'string',
    defaultValue: 'test value'
  });
});