define('frontend/mirage/models/report', ['exports', 'ember-cli-mirage'], function (exports, _emberCliMirage) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberCliMirage.Model.extend({
    invitations: (0, _emberCliMirage.hasMany)(),
    patientSession: (0, _emberCliMirage.belongsTo)(),
    reportScales: (0, _emberCliMirage.hasMany)()
  });
});