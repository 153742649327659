define('frontend/mirage/factories/patient', ['exports', 'ember-cli-mirage', 'faker'], function (exports, _emberCliMirage, _faker) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberCliMirage.Factory.extend({
    id: _faker.default.random.uuid,

    email: function email(i) {
      return 'patient' + i + '@mirah.com';
    },

    mrn: function mrn(i) {
      return 'mrn' + i;
    },

    firstName: _faker.default.name.firstName,
    lastName: _faker.default.name.lastName,
    gender: _faker.default.random.arrayElement(["M", "F"]),
    lastSsn: '1234',
    dob: _faker.default.date.past,
    allowEmail: true,
    allowText: true,

    user: (0, _emberCliMirage.association)(),

    afterCreate: function afterCreate(patient, server) {
      var participant = server.create('participant', Ember.merge({ participantType: 'patient' }, patient.attrs));
      patient.clientParticipants.add(participant);
      patient.save();
    },


    withRelatedPeople: (0, _emberCliMirage.trait)({
      afterCreate: function afterCreate(patient, server) {
        var relatedPerson = server.create('relatedPerson', { patient: patient });
        var participant = server.create('participant', relatedPerson.attrs);
        patient.clientParticipants.add(participant);
        patient.save();
      }
    })
  });
});