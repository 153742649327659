define('frontend/mirage/factories/entity-measurement-plan', ['exports', 'ember-cli-mirage', 'faker'], function (exports, _emberCliMirage, _faker) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberCliMirage.Factory.extend({
    id: _faker.default.random.uuid,
    measurementPlan: (0, _emberCliMirage.association)(),
    active: true
  });
});