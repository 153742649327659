define('frontend/mirage/models/patient-session', ['exports', 'ember-cli-mirage'], function (exports, _emberCliMirage) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberCliMirage.Model.extend({
    patient: (0, _emberCliMirage.belongsTo)(),
    provider: (0, _emberCliMirage.belongsTo)(),
    careEpisode: (0, _emberCliMirage.belongsTo)(),
    assessmentReport: (0, _emberCliMirage.belongsTo)('report'),
    schedulingAppointment: (0, _emberCliMirage.belongsTo)('appointment')
  });
});