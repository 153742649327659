define('frontend/mirage/factories/tabular-metric', ['exports', 'ember-cli-mirage', 'faker'], function (exports, _emberCliMirage, _faker) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberCliMirage.Factory.extend({
    id: _faker.default.random.uuid,

    shortcode: "test.report",

    startDate: _faker.default.date.past,
    endDate: _faker.default.date.recent,

    headers: [],
    rowData: [],
    title: "Test Report",
    description: ""
  });
});