define('frontend/mirage/factories/payor-contract', ['exports', 'ember-cli-mirage', 'faker'], function (exports, _emberCliMirage, _faker) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberCliMirage.Factory.extend({
    name: function name(i) {
      return "Contract " + i;
    },
    startDate: _faker.default.date.past(10)
  });
});