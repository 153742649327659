define('frontend/mirage/models/institute-payor', ['exports', 'ember-cli-mirage'], function (exports, _emberCliMirage) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberCliMirage.Model.extend({
    payor: (0, _emberCliMirage.belongsTo)(),
    institute: (0, _emberCliMirage.belongsTo)(),
    billingPayorContracts: (0, _emberCliMirage.hasMany)('payor-contract')
  });
});