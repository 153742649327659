define('frontend/mirage/factories/provider', ['exports', 'ember-cli-mirage', 'faker'], function (exports, _emberCliMirage, _faker) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberCliMirage.Factory.extend({
    id: _faker.default.random.uuid,
    name: function name() {
      return _faker.default.name.firstName() + " " + _faker.default.name.lastName();
    },
    orderedName: function orderedName() {
      return _faker.default.name.lastName() + ', ' + _faker.default.name.firstName();
    },
    practicing: true,
    defaultRelationship: _faker.default.random.arrayElement(["psychiatrist", "psychologist"])
  });
});