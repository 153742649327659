define('frontend/mirage/factories/patient-session', ['exports', 'ember-cli-mirage', 'faker'], function (exports, _emberCliMirage, _faker) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberCliMirage.Factory.extend({
    id: _faker.default.random.uuid,
    status: 'complete',

    patient: (0, _emberCliMirage.association)(),

    scheduled: (0, _emberCliMirage.trait)({
      status: 'new'
    }),

    withScales: (0, _emberCliMirage.trait)({
      afterCreate: function afterCreate(session, server) {
        // Needs to not be done as an association so other associations are ready at the right time.
        server.create('report', 'withScales', { patientSession: session, scaleIds: session.scaleIds });
      }
    })
  });
});