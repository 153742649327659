define('frontend/mirage/factories/charge-item-code', ['exports', 'ember-cli-mirage'], function (exports, _emberCliMirage) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberCliMirage.Factory.extend({
    code: function code(n) {
      return 'code' + n;
    }
  });
});