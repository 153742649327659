define('frontend/mirage/factories/measure-schedule-calculator-request', ['exports', 'ember-cli-mirage'], function (exports, _emberCliMirage) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberCliMirage.Factory.extend({
    results: function results() {
      return [{ date: new Date(), session_number: 1, scale_ids: ['6c70337a-5611-47a1-906c-02cf8ff4a512'] }];
    }
  });
});