define('frontend/mirage/factories/experiment', ['exports', 'ember-cli-mirage', 'faker'], function (exports, _emberCliMirage, _faker) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberCliMirage.Factory.extend({
    id: _faker.default.random.uuid,
    name: function name(i) {
      return 'Experiment ' + i;
    },
    enabled: true,
    triggerEvents: ['care_episode_created']
  });
});