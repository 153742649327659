define('frontend/mirage/config', ['exports', 'ember-cli-mirage', 'ember-cli-mirage/orm/collection', 'frontend/mirage/response-maps/patients', 'frontend/mirage/response-maps/patient-scale-targets', 'frontend/mirage/response-maps/report-bundles', 'frontend/mirage/response-maps/preloads', 'frontend/mirage/response-maps/computed-values-answers', 'frontend/mirage/response-maps/questions', 'frontend/mirage/response-maps/populations', 'frontend/mirage/response-maps/participants', 'frontend/mirage/response-maps/users'], function (exports, _emberCliMirage, _collection, _patients, _patientScaleTargets, _reportBundles, _preloads, _computedValuesAnswers, _questions, _populations, _participants, _users) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function () {
    var patientMap = _patients.default;
    var patientScaleTargetMap = _patientScaleTargets.default;
    var reportBundleMap = _reportBundles.default;
    var preloadMap = _preloads.default;
    var computedValuesAnswersMap = _computedValuesAnswers.default;
    var questionMap = _questions.default;
    var populationMap = _populations.default;
    var participantMap = _participants.default;
    var userMap = _users.default;

    var signIn = function signIn(schema, request) {
      var attrs = JSON.parse(request.requestBody);
      var email = Ember.get(attrs, "user.username");
      var password = Ember.get(attrs, "user.password");

      var user = schema.db.users.firstOrCreate({ email: email, username: email });
      if (password == "password") {
        return Ember.merge(user, { user_type: "provider", role: "clinician", authentication_token: "good_token" });
      } else {
        return new _emberCliMirage.default.Response(401, {}, { error: "Permission denied" });
      }
    };

    this.get("/api/keepalive", function () {
      return [];
    });

    // User management
    this.post('/users/sign_in', signIn);
    this.get('/users/sign_in', signIn);

    this.get('/api/preloads', function () {
      return preloadMap.all;
    });

    this.post('/api/user-passwords');

    this.get('/api/patients', function (_ref, request) {
      var patients = _ref.patients;

      if (request.queryParams['filter[email]']) {
        return withPaging(patients.where({ email: request.queryParams['filter[email]'] }), request);
      } else {
        return withPaging(patients.all(), request);
      }
    });

    this.get('/api/users');
    this.get('/api/users/:id');

    for (var key in userMap) {
      this.get('/api/users/' + key, function () {
        return userMap[key];
      });

      this.get('/api/users/' + key + '/pending-sessions', function () {
        return { data: [] };
      });
    }

    this.get('/api/business-events');
    this.get('/api/business-events/:id');

    this.get('/api/analytics-events');
    this.get('/api/analytics-events/:id');

    this.get('/api/patients/:id', function (schema, request) {
      if (patientMap[request.params.id]) {
        return patientMap[request.params.id];
      } else {
        return schema.patients.find(request.params.id);
      }
    });
    this.post('/api/patients');
    this.put('/api/patients/:id');

    this.get('/api/patients/:id/assessment-patient-scale-targets', function (schema, request) {
      return patientScaleTargetMap[request.params.id];
    });

    this.get('/api/patients/:id/care-episodes', function (schema) {
      return schema.careEpisodes.all();
    });

    this.get('/api/assessment/report-bundles/:id', function (schema, request) {
      return reportBundleMap[request.params.id];
    });

    this.get('/api/assessment/computed-values/:id/answers', function (schema, request) {
      return computedValuesAnswersMap[request.params.id];
    });

    this.get('/api/payors');
    this.get('/api/payors/:id');

    this.get('/api/institute-payors');
    this.get('/api/institute-payors/:id');
    this.put('/api/institute-payors/:id');
    this.post('/api/institute-payors');

    this.get('/api/patient-sessions/:id');
    this.post('/api/patient-sessions');
    this.put('/api/patient-sessions/:id');

    this.get('/api/care-episodes');
    this.get('/api/care-episodes/:id');
    this.post('/api/care-episodes');
    this.put('/api/care-episodes/:id');

    this.get('/api/care-plans/:id');
    this.post('/api/care-plans');
    this.put('/api/care-plans/:id');

    this.get('/api/patient-care-plans/:id');
    this.post('/api/patient-care-plans');
    this.put('/api/patient-care-plans/:id');

    // Institutes
    this.get('/api/institutes');
    this.get('/api/institutes/:id');
    this.post('/api/institutes');
    this.put('/api/institutes/:id');
    this.del('/api/institutes/:id');

    this.get('/api/organizations');
    this.get('/api/organizations/:id');

    this.get('/api/flags');
    this.get('/api/flags/:id');

    this.get('/api/current-institutes', function (schema) {
      if (schema.currentInstitutes.all().models.length == 0) {
        server.create('currentInstitute');
      }

      return schema.currentInstitutes.all();
    });

    this.get('/api/assessment/invitations', function (schema, request) {
      if (request.queryParams && request.queryParams['filter[preset]']) {
        var preset = request.queryParams['filter[preset]'];

        if (preset == 'pending') {
          return schema.invitations.where({ dateCompleted: null });
        } else {
          return schema.invitations.all().filter(function (i) {
            return i.dateCompleted != null;
          });
        }
      }
      return schema.invitations.all();
    });

    this.get('/api/patient-sessions', function (schema, request) {
      if (request.queryParams && request.queryParams['filter[preset]']) {
        var preset = request.queryParams['filter[preset]'];
        //for now, just hack the filter to assume complete/not complete
        if (preset === "with_completed_reports") {
          return schema.patientSessions.all().filter(function (s) {
            return s.status === "complete";
          });
        } else if (preset === "with_pending_reports") {
          return schema.patientSessions.all().filter(function (s) {
            return s.status != "complete";
          });
        }
      }
      return schema.patientSessions.all();
    });

    this.get('/api/providers');
    this.get('/api/providers/:id');

    this.get('/api/assessment/scales');
    this.get('/api/assessment/scales/:id');

    this.get('/api/assessment/populations', function () {
      return populationMap.all;
    });
    this.get('/api/assessment/populations/:id');

    this.get('/api/assessment/questions', function () {
      return questionMap.all;
    });
    this.get('/api/assessment/questions/:id', function (schema, request) {
      return questionMap[request.params.id];
    });

    this.post('/api/assessment/invitations');
    this.get('/api/assessment/invitations/:id');

    this.get('/api/assessment/invitations/:id/participant', function (schema, request) {
      return participantMap[request.params.id];
    });

    this.get('/api/assessment/norms');
    this.get('/api/assessment/norms/:id');

    this.post('/api/assessment/invitations/:id/resend-email', function (schema, request) {
      return schema.invitations.find(request.params.id);
    });

    this.get("/api/assessment/assessments");

    this.post('/api/assessment/reports');
    this.get('/api/assessment/reports/:id');
    this.get('/api/assessment/reports');

    this.post('/api/metric-data', function (schema) {

      var attrs = this.normalizedRequestAttrs();

      if (!attrs.interval) {
        attrs.interval = 'day';
      }

      if (!attrs.startDate) {
        attrs.startDate = moment().add(-1, 'month').toDate();
      }

      if (!attrs.endDate) {
        attrs.endDate = moment().toDate();
      }

      attrs.results = [{
        "interval": attrs.endDate,
        "count": 2,
        "denominator": 4,
        "percentage": 50
      }];

      return schema.metricData.create(attrs);
    });

    this.post('/api/tabular-metrics', function (schema) {
      var attrs = this.normalizedRequestAttrs();

      var result = {
        "id": attrs.id,
        "headers": [],
        "rowData": [],
        "title": "Tabular Report",
        "description": ""
      };

      return schema.tabularMetrics.create(result);
    });

    this.get('/api/user-stats');

    this.get("/api/import/data-files");

    this.get('/api/entity-tree-nodes');
    this.get('/api/entity-tree-nodes/:id');

    this.get('/api/care/measurement-plans');
    this.get('/api/care/measurement-plans/:id');
    this.get('/api/care/measurement-plan-scales');
    this.get('/api/care/measurement-plan-scales/:id');
    this.get('/api/care/entity-measurement-plans');
    this.get('/api/care/entity-measurement-plans/:id');
    this.patch('/api/care/entity-measurement-plans/:id');

    this.post('/api/care/entity-measurement-plans', function (schema, request) {
      var _this = this;

      var attrs = this._getAttrsForRequest(request, 'entityMeasurementPlan');

      if (attrs.nestedData && attrs.nestedData['new-measurement-plan']) {
        var measurementPlanAttrs = this._getAttrsForRequest({ requestBody: JSON.stringify({ data: attrs.nestedData['new-measurement-plan'] }) }, 'measurementPlan');
        var measurementPlanScaleAttrs = measurementPlanAttrs.nestedData['measurement-plan-scales'].map(function (data) {
          return _this._getAttrsForRequest({ requestBody: JSON.stringify({ data: data }) }, 'measurementPlanScale');
        });
        schema.measurementPlans.create(measurementPlanAttrs);
        measurementPlanScaleAttrs.forEach(function (planScaleAttrs) {
          return schema.measurementPlanScales.create(planScaleAttrs);
        });
      }

      schema.entityTreeNodes.create({ id: attrs.entityTreeNodeId }); // Don't bother with any of the actual fields.

      return schema.entityMeasurementPlans.create(attrs);
    });

    this.post('/api/care/measure-schedule-calculator-requests', function () {
      // By doing it this way, it'll automatically get the results parameter filled in from the factory
      var attrs = this.normalizedRequestAttrs();
      return server.create('measure-schedule-calculator-request', attrs);
    });

    this.get('/api/billing/payor-contracts');
    this.get('/api/billing/payor-contracts/:id');
    this.post('/api/billing/payor-contracts');
    this.patch('/api/billing/payor-contracts/:id');

    this.get('/api/billing/payor-contract-codes');
    this.get('/api/billing/payor-contract-codes/:id');
    this.post('/api/billing/payor-contract-codes');
    this.patch('/api/billing/payor-contract-codes/:id');

    this.get('/api/billing/coverages');
    this.get('/api/billing/coverages/:id');
    this.post('/api/billing/coverages');
    this.patch('/api/billing/coverages/:id');

    this.get('/api/billing/charges');
    this.get('/api/billing/charges/:id');
    this.post('/api/billing/charges');
    this.patch('/api/billing/charges/:id');

    this.get('/api/billing/charge-items');
    this.get('/api/billing/charge-items/:id');
    this.post('/api/billing/charge-items');
    this.patch('/api/billing/charge-items/:id');

    this.get('/api/assessment/goals');
    this.get('/api/assessment/goals/:id');
    this.post('/api/assessment/goals');
    this.patch('/api/assessment/goals/:id');

    this.get('/api/assessment/goal-answers');
    this.get('/api/assessment/goal-answers/:id');
    this.post('/api/assessment/goal-answers');
    this.patch('/api/assessment/goal-answers/:id');

    this.get('/api/assessment/scale-scorers');
    this.get('/api/assessment/scale-scorers/:id');

    this.get('/api/configuration-properties');
    this.get('/api/configuration-properties/:id');

    this.get('/api/configuration-values');
    this.get('/api/configuration-values/:id');
    this.post('/api/configuration-values');
    this.put('/api/configuration-values/:id');
    this.del('/api/configuration-values/:id');

    this.get('/api/active-configuration-values');
    this.get('/api/active-configuration-values/:id');

    this.get('/api/experiments');
    this.get('/api/experiments/:id');
    this.post('/api/experiments');
    this.del('/api/experiments');

    this.get('/api/experiment-variants');
    this.get('/api/experiment-variants/:id');
    this.post('/api/experiment-variants');
    this.del('/api/experiment-variants');

    this.get('/api/experiment-decisions');
    this.get('/api/experiment-decisions/:id');
    this.post('/api/experiment-decisions');
    this.del('/api/experiment-decisions');

    // Turn on this to allow real requests to go through to the server. May
    // break test runs
    //this.passthrough();


    // These comments are here to help you get started. Feel free to delete them.

    /*
      Config (with defaults).
       Note: these only affect routes defined *after* them!
    */

    // this.urlPrefix = '';    // make this `http://localhost:8080`, for example, if your API is on a different server
    // this.namespace = '';    // make this `api`, for example, if your API is namespaced
    // this.timing = 400;      // delay for each request, automatically set to 0 during testing

    /*
      Shorthand cheatsheet:
       this.get('/posts');
      this.post('/posts');
      this.get('/posts/:id');
      this.put('/posts/:id'); // or this.patch
      this.del('/posts/:id');
    */
  };

  function withPaging(response, request) {
    var limit = parseInt(request.queryParams["page[limit]"]);
    var offset = parseInt(request.queryParams["page[offset]"]);
    if (limit || offset) {
      if (!offset) {
        offset = 0;
      }

      var finalResult = [];

      response.models.map(function (m, i) {
        if (i >= offset && i < offset + limit) {
          finalResult.push(m);
        }
      });

      return new _collection.default(response.modelName, finalResult);
    } else {
      return response;
    }
  }
});