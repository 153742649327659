define('frontend/mirage/factories/user', ['exports', 'ember-cli-mirage', 'faker'], function (exports, _emberCliMirage, _faker) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberCliMirage.Factory.extend({
    id: _faker.default.random.uuid,

    email: function email(i) {
      return 'user' + i + '@mirah.com';
    },

    username: function username(i) {
      return 'user' + i + '@mirah.com';
    },

    name: function name() {
      return _faker.default.name.firstName() + " " + _faker.default.name.lastName();
    },

    allowEmail: true,
    allowText: true,

    user_type: "provider"
  });
});