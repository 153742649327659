define("frontend/mirage/response-maps/patient-scale-targets", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    // Julie Brown from demo.mirah.com
    "311ec8bc-6dd4-42cd-9dc3-0c72efaacf4f": { "data": [], "meta": { "record-count": 0 }, "links": { "first": "/api/patients/311ec8bc-6dd4-42cd-9dc3-0c72efaacf4f/assessment-patient-scale-targets?page%5Blimit%5D=10&page%5Boffset%5D=0", "last": "/api/patients/311ec8bc-6dd4-42cd-9dc3-0c72efaacf4f/assessment-patient-scale-targets?page%5Blimit%5D=10&page%5Boffset%5D=0" } },
    // Steve Smith from localhost
    "bd02c0fd-37e5-4d0d-bc02-7d48199a38e7": { "data": [], "meta": { "record-count": 0 }, "links": { "first": "/api/patients/bd02c0fd-37e5-4d0d-bc02-7d48199a38e7/assessment-patient-scale-targets?page%5Blimit%5D=10&page%5Boffset%5D=0", "last": "/api/patients/bd02c0fd-37e5-4d0d-bc02-7d48199a38e7/assessment-patient-scale-targets?page%5Blimit%5D=10&page%5Boffset%5D=0" } },
    // Tyler Blue localhost
    "3d746b06-5dc6-4638-829e-38666613a296": { "data": [], "meta": { "record-count": 0 }, "links": { "first": "/api/patients/3d746b06-5dc6-4638-829e-38666613a296/assessment-patient-scale-targets?page%5Blimit%5D=10&page%5Boffset%5D=0", "last": "/api/patients/3d746b06-5dc6-4638-829e-38666613a296/assessment-patient-scale-targets?page%5Blimit%5D=10&page%5Boffset%5D=0" } }
  };
});