define('frontend/mirage/identity-managers/application', ['exports', 'ember-cli-uuid/utils/uuid-helpers'], function (exports, _uuidHelpers) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) {
      throw new TypeError("Cannot call a class as a function");
    }
  }

  var _createClass = function () {
    function defineProperties(target, props) {
      for (var i = 0; i < props.length; i++) {
        var descriptor = props[i];
        descriptor.enumerable = descriptor.enumerable || false;
        descriptor.configurable = true;
        if ("value" in descriptor) descriptor.writable = true;
        Object.defineProperty(target, descriptor.key, descriptor);
      }
    }

    return function (Constructor, protoProps, staticProps) {
      if (protoProps) defineProperties(Constructor.prototype, protoProps);
      if (staticProps) defineProperties(Constructor, staticProps);
      return Constructor;
    };
  }();

  var _class = function () {
    function _class() {
      _classCallCheck(this, _class);

      this.ids = new Set();
    }

    // Returns a new unused unique identifier.


    _createClass(_class, [{
      key: 'fetch',
      value: function fetch() {
        var u = (0, _uuidHelpers.default)();
        while (this.ids.has(u)) {
          u = (0, _uuidHelpers.default)();
        }

        this.ids.add(u);

        return u;
      }
    }, {
      key: 'set',
      value: function set(id) {
        if (this.ids.has(id)) {
          throw new Error('ID ' + id + ' has already been used.');
        }

        this.ids.add(id);
      }
    }, {
      key: 'reset',
      value: function reset() {
        this.ids.clear();
      }
    }]);

    return _class;
  }();

  exports.default = _class;
});