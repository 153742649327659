define('frontend/mirage/factories/institute-payor', ['exports', 'ember-cli-mirage'], function (exports, _emberCliMirage) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberCliMirage.Factory.extend({
    payor: (0, _emberCliMirage.association)(),
    institute: (0, _emberCliMirage.association)(),
    name: function name(i) {
      return 'Payor ' + i;
    },
    primaryId: function primaryId(i) {
      return 'payor' + i;
    }
  });
});