define('frontend/mirage/factories/goal', ['exports', 'ember-cli-mirage', 'faker'], function (exports, _emberCliMirage, _faker) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberCliMirage.Factory.extend({
    id: _faker.default.random.uuid,
    status: 'active',
    patientText: function patientText(i) {
      return 'Goal ' + i;
    },
    user: (0, _emberCliMirage.association)(),
    patient: (0, _emberCliMirage.association)(),
    goalType: 'goal_attainment_scaling'
  });
});