define('frontend/mirage/models/charge', ['exports', 'ember-cli-mirage'], function (exports, _emberCliMirage) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberCliMirage.Model.extend({
    patientSession: (0, _emberCliMirage.belongsTo)(),
    payorContractCode: (0, _emberCliMirage.belongsTo)(),
    payorContract: (0, _emberCliMirage.belongsTo)(),
    institutePayor: (0, _emberCliMirage.belongsTo)(),
    organization: (0, _emberCliMirage.belongsTo)(),
    patient: (0, _emberCliMirage.belongsTo)(),
    chargeItemCode: (0, _emberCliMirage.belongsTo)(),
    provider: (0, _emberCliMirage.belongsTo)(),
    chargeItems: (0, _emberCliMirage.hasMany)()
  });
});