define('frontend/mirage/factories/data-file', ['exports', 'ember-cli-mirage', 'faker'], function (exports, _emberCliMirage, _faker) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberCliMirage.Factory.extend({
    id: _faker.default.random.uuid,
    fileName: function fileName(i) {
      return 'files/' + i;
    },
    recordsUpdated: _faker.default.random.number,
    recordsCreated: _faker.default.random.number,
    recordsSkipped: _faker.default.random.number,
    recordsErrored: _faker.default.random.number,
    status: _faker.default.random.arrayElement(["new", "imported", "imported_with_errors"])
  });
});