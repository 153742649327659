define('frontend/mirage/factories/report-scale-participant', ['exports', 'ember-cli-mirage'], function (exports, _emberCliMirage) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberCliMirage.Factory.extend({
    reportScale: (0, _emberCliMirage.association)(),
    participant: (0, _emberCliMirage.association)(),
    invitation: (0, _emberCliMirage.association)(),

    afterCreate: function afterCreate(rsp) {
      if (!rsp.participant) {
        rsp.participant = Ember.get(rsp, 'reportScale.report.patientSession.patient');
      }

      if (!rsp.user) {
        rsp.user = rsp.participant.user;
      }
    }
  });
});