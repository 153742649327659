define('frontend/mirage/factories/metric-datum', ['exports', 'ember-cli-mirage', 'faker'], function (exports, _emberCliMirage, _faker) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberCliMirage.Factory.extend({
    id: _faker.default.random.uuid,

    shortcode: "test.metric",

    startDate: _faker.default.date.past,
    endDate: _faker.default.date.recent,
    interval: "day",
    results: [{
      "interval": _faker.default.date.weekday(),
      "count": 2
    }]

  });
});