define('frontend/mirage/factories/invitation', ['exports', 'ember-cli-mirage', 'faker'], function (exports, _emberCliMirage, _faker) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberCliMirage.Factory.extend({
    id: _faker.default.random.uuid,
    externalUrl: function externalUrl() {
      return '/patient/assessment/invitation/{faker.random.uuid()}/login';
    },
    assessmentUrl: function assessmentUrl() {
      return 'https://assessment-test.mirah.com/?sguid=' + _faker.default.random.uuid();
    },
    dateEmailed: _faker.default.date.past,
    dateCompleted: _faker.default.date.recent,
    phase: "before",
    email: function email(i) {
      return 'patient' + i + '@mirah.com';
    },

    opensAt: _faker.default.date.future,
    closesAt: _faker.default.date.future
  });
});