define('frontend/mirage/factories/charge-item', ['exports', 'ember-cli-mirage', 'faker'], function (exports, _emberCliMirage, _faker) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberCliMirage.Factory.extend({
    id: _faker.default.random.uuid,
    assessmentReportScaleParticipant: (0, _emberCliMirage.association)(),
    charge: (0, _emberCliMirage.association)(),

    status: _faker.default.random.arrayElement(['billed', 'billable', 'not_billable']),
    eligibilityStatus: _faker.default.random.arrayElement(['billed', 'billable', 'not_billable']),
    algorithmState: _faker.default.random.arrayElement(['assessment_completed_session_fulfilled', 'assessment_completed_first_session_missed', 'assessment_completed_session_missed', 'assessment_completed_session_outstanding', 'assessment_begun_session_fulfilled', 'assessment_begun_first_session_missed'])
  });
});