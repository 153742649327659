define('frontend/mirage/models/patient', ['exports', 'ember-cli-mirage'], function (exports, _emberCliMirage) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberCliMirage.Model.extend({
    patientSessions: (0, _emberCliMirage.hasMany)(),
    user: (0, _emberCliMirage.belongsTo)({ inverse: 'patient' }),
    clientParticipants: (0, _emberCliMirage.hasMany)({ polymorphic: true, inverse: null, isReflexive: true }),
    relatedPeople: (0, _emberCliMirage.hasMany)(),
    lastAssessmentReport: (0, _emberCliMirage.belongsTo)('report'),
    patientStat: (0, _emberCliMirage.belongsTo)(),
    billingCoverages: (0, _emberCliMirage.hasMany)('coverage'),
    experimentDecisions: (0, _emberCliMirage.hasMany)('experimentDecision')
  });
});